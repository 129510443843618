import React from 'react';
import { Link } from 'react-router-dom';
import instaIcon from '../assets/icons/insta48.png';
import twitterIcon from '../assets/icons/twitterx-48.png';
import linkedinIcon from '../assets/icons/linkdin48.png';
import appleIcon from '../assets/icons/apple48.png';
import spotifyIcon from '../assets/icons/spotify48.png';
import '../assets/css/footer.css';

const Footer = () => (
  <footer>
    <div id="social-media">
      <h3>Social Media</h3>
      <a href="https://www.instagram.com/avid_fayaz/"><img src={instaIcon} width="36" height="36" alt="Instagram" /></a>
      <a href="https://twitter.com/AvidFayaz"><img src={twitterIcon} width="36" height="36" alt="Twitter" /></a>
      <a href="https://www.linkedin.com/in/avid-fayaz-98825a252/"><img src={linkedinIcon} width="36" height="36" alt="LinkedIn" /></a>
    </div>
    <div id="podcasts">
      <h3>Podcasts</h3>
      <a href="https://podcasts.apple.com/se/podcast/the-ai-pod/id1668409143"><img src={appleIcon} width="36" height="36" alt="Apple Podcasts" /> Apple Podcasts</a>
      <a href="https://open.spotify.com/show/5TWtEjXoqcifsH3BgE7GHi"><img src={spotifyIcon} width="36" height="36" alt="Spotify" /> Spotify</a>
    </div>
    <div id="about-rights">
      <Link to="/about">About</Link>
      <p>&copy; 2024 All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
