// src/components/Writings.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/writings.css';
import writing1Image from '../assets/writings/PopCulture/image1.jpg';
import writing2Image from '../assets/writings/eacc/black_and_white.png';
import writing3Image from '../assets/writings/goodness/main.png';

const slugify = (text) => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');
};

const writingsData = [
  {
    title: "Be Good for Goodness' Sake",
    description: "Augustine, Christianity, and the Ethics of Goodness",
    imageUrl: writing3Image,
    folder: "goodness",
    slug: "be-good-for-goodness-sake"
  },
  {
    title: "e/acc as a Religion: A Technologist's Right to Choose",
    description: "A modest proposal to stand for objectivism and avoid mandatory sustainability courses.",
    imageUrl: writing2Image,
    folder: "eacc",
    slug: "eacc-as-a-religion-technologists-right-to-choose"
  },
  {
    title: "Pop Culture: The AI Hype and What Follows",
    description: "As the AI bubble might be bursting, there are still many things to look forward to.",
    imageUrl: writing1Image,
    folder: "PopCulture",
    slug: "pop-culture-ai-hype-and-what-follows"
  }

];

const Writings = () => (
  <div id="writings" className="content">
    <h2>Writings</h2>
    <div className="writings-list">
      {writingsData.map((writing, index) => (
        <Link to={`/writings/${writing.folder}/${writing.slug}`} key={index} className="writing-link-box">
          <div className="writing-thumbnail">
            <img src={writing.imageUrl} alt={writing.title} />
          </div>
          <div className="writing-description">
            <h3>{writing.title}</h3>
            <p>{writing.description}</p>
          </div>
        </Link>
      ))}
    </div>
  </div>
);

export default Writings;