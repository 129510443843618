import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import SideNav from './components/SideNav';
import Home from './components/Home';
import Writings from './components/Writings';
import WritingDetail from './components/WritingDetail';
import Podcasts from './components/Podcasts';
import About from './components/About';
import Contact from './components/Contact';
import './assets/css/main.css';

const App = () => {
  useEffect(() => {
    const handleScroll = () => {
      const trigger = document.getElementById('sidenav-trigger');
      const headerHeight = document.querySelector('header').offsetHeight;
      const isSmallScreen = window.innerWidth <= 768;
      const buffer = isSmallScreen ? 0 : 10; // 0 for small screens, 10 for larger screens

      if (window.pageYOffset > headerHeight + buffer) {
        trigger.classList.add('fixed');
        trigger.style.top = isSmallScreen ? '0px' : '10px';
      } else {
        trigger.classList.remove('fixed');
        trigger.style.top = `${headerHeight + buffer - window.pageYOffset}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll); // Recalculate on resize

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const openNav = () => {
    document.getElementById('mySidenav').style.width = '250px';
    document.getElementById('sidenav-trigger').style.display = 'none';
  };

  const closeNav = () => {
    document.getElementById('mySidenav').style.width = '0';
    document.getElementById('sidenav-trigger').style.display = 'flex'; // Ensure the trigger is displayed flexibly
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <div className="App">
        <Header />
        <SideNav closeNav={closeNav} />
        <div id="sidenav-trigger" onClick={openNav}>
          <span style={{ fontSize: '30px', cursor: 'pointer' }}>&#9776;</span>
        </div>
        <div id="main-content">
          <Routes>
          <Route 
              path="/writings/eacc" 
              element={<Navigate to="/writings/eacc/eacc-as-a-religion-technologists-right-to-choose" replace />} 
            />
            <Route 
              path="/writings/PopCulture" 
              element={<Navigate to="/writings/PopCulture/pop-culture-ai-hype-and-what-follows" replace />} 
            />
            <Route path="/" element={<Home />} />
            <Route path="/writings" element={<Writings />} />
            <Route path="/writings/:folder/:slug" element={<WritingDetail />} />
            <Route path="/podcasts" element={<Podcasts />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
