import React from 'react';
import '../assets/css/sidenav.css';
import instaIcon from '../assets/icons/insta48.png';
import twitterIcon from '../assets/icons/twitterx-48.png';
import linkedinIcon from '../assets/icons/linkdin48.png';

const SideNav = ({ closeNav }) => {
  return (
    <div id="mySidenav" className="sidenav">
      <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>
      <a href="/">Home</a>
      <a href="/writings">Writings</a>
      <a href="/podcasts">Podcasts</a>
      <a href="/contact">Contact</a>
      <a href="/about">About</a>

    </div>
  );
};

export default SideNav;
