import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/home.css';
import profileImage from '../assets/images/moi.jpeg';

const Home = () => (
  <div id="home" className="content">
    <div className="text-container">
      <h2>The personal website of Avid Fayaz</h2>
      <div className="links-container">
        <Link to="/about" className="link-box">
          <h3>About</h3>
          <p>Learn more about Avid, his background, and interests</p>
        </Link>
        <Link to="/writings" className="link-box">
          <h3>Writings</h3>
          <p>Personal blog and writings on various subjects and interests</p>
        </Link>
        <Link to="/podcasts" className="link-box">
          <h3>Podcasts</h3>
          <p>Listen to podcasts, covering a range of subjects and interviews</p>
        </Link>
        <Link to="/contact" className="link-box">
          <h3>Contact</h3>
          <p>Get in touch for inquiries, or just to say hi</p>
        </Link>
      </div>
    </div>
    <div className="image-container">
      <img src={profileImage} alt="Avid Fayaz" className="profile-image"/>
    </div>
  </div>
);

export default Home;
