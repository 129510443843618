// src/components/Contact.js
import React from 'react';
import '../assets/css/contact.css';

const Contact = () => (
  <div id="contact" className="content">
    <h2>Contact</h2>
    <p>You can contact me via my email: <a href="mailto:avidf@kth.se">avidf@kth.se</a></p>
  </div>
);

export default Contact;



/*
const Contact = () => (
  <div id="contact" className="content">
    <h2>Contact</h2>
    <form action="mailto:avid_f80@yahoo.com" method="post" encType="text/plain">
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" placeholder="johnny@appleseed.com" required />
      </div>
      <div className="form-group">
        <label htmlFor="company">Subject:</label>
        <input type="text" id="company" name="company" placeholder="Apples" />
      </div>
      <div className="form-group">
        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" placeholder="How do you like them apples, ..." required></textarea>
      </div>
      <div className="form-group">
        <button type="submit">Send</button>
      </div>
    </form>
  </div>
);
*/