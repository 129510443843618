// src/components/Podcasts.js
import React from 'react';
import '../assets/css/podcasts.css';

const podcasts = [
    {
    id: 11,
    title: "Åsa Unander-Scharin: Opera, dance & robotics",
    videoUrl: "https://www.youtube.com/watch?v=H8VVYxlNjqI",
    thumbnail: "https://img.youtube.com/vi/H8VVYxlNjqI/0.jpg"
    },
    {
        id: 10,
        title: "Mario Romero: Visualisation & AI",
        videoUrl: "https://www.youtube.com/watch?v=Kbnw1kq34ts",
        thumbnail: "https://img.youtube.com/vi/Kbnw1kq34ts/0.jpg"
    },
    {
        id: 9,
        title: "Magnus Boman: AI and Health ",
        videoUrl: "https://www.youtube.com/watch?v=GDokI_jYwGg",
        thumbnail: "https://img.youtube.com/vi/GDokI_jYwGg/0.jpg"
    },
    {
        id: 8,
        title: "Haluk Akay: Design, Sustainability, and AI",
        videoUrl: "https://www.youtube.com/watch?v=n0PpCixww2o",
        thumbnail: "https://img.youtube.com/vi/n0PpCixww2o/0.jpg"
    },
    {
        id: 7,
        title: "Hedvig Kjellström: Communication, Cognition, and AI",
        videoUrl: "https://www.youtube.com/watch?v=adoU4aW3Flg",
        thumbnail: "https://img.youtube.com/vi/adoU4aW3Flg/0.jpg"
    },
    {
        id: 6,
        title: "André Holzapfel: AI, Music, and creativity",
        videoUrl: "https://www.youtube.com/watch?v=AwHgMe2sFhU",
        thumbnail: "https://img.youtube.com/vi/AwHgMe2sFhU/0.jpg"
    },
  {
    id: 5,
    title: "Per Andersson: Organisational & Business Effects of AI",
    videoUrl: "https://www.youtube.com/watch?v=0QCG6EH4ibY",
    thumbnail: "https://img.youtube.com/vi/0QCG6EH4ibY/0.jpg"
  },
  {
    id: 4,
    title: "Clàudia Figueras: The Ethics of AI",
    videoUrl: "https://www.youtube.com/watch?v=v7romkXS7Hk",
    thumbnail: "https://img.youtube.com/vi/v7romkXS7Hk/0.jpg"
  },
  {
    id: 3,
    title: "Fredrik Strand: Radiology, breast cancer, and AI",
    videoUrl: "https://www.youtube.com/watch?v=LLkC1HgC9eU",
    thumbnail: "https://img.youtube.com/vi/LLkC1HgC9eU/0.jpg"
  },
  {
    id: 2,
    title: "Bob L.T. Sturm: Music and AI",
    videoUrl: "https://www.youtube.com/watch?v=vMjpzj96hnE",
    thumbnail: "https://img.youtube.com/vi/vMjpzj96hnE/0.jpg"
  },
  {
    id: 1,
    title: "Gabriel Skantze: Social robots, Language, and Communication",
    videoUrl: "https://www.youtube.com/watch?v=qEgrBeqV7AE",
    thumbnail: "https://img.youtube.com/vi/qEgrBeqV7AE/0.jpg"
  }
];

const Podcasts = () => (
  <div id="podcasts" className="content">
    <h2>Podcasts</h2>
    {podcasts.map(podcast => (
      <a key={podcast.id} href={podcast.videoUrl} className="link-box">
        <div className="thumbnail">
          <img src={podcast.thumbnail} alt="YouTube Video Thumbnail" />
        </div>
        <div className="video-description">
          {`#${podcast.id} ${podcast.title}`}
        </div>
      </a>
    ))}
  </div>
);

export default Podcasts;
