// src/components/About.js
import React from 'react';
import libraryImage from '../assets/images/library.jpg';

const About = () => (
  <div id="about" className="content">
    <h2>About</h2>
    <h3>Avid Fayaz
    </h3>
    <img src={libraryImage} alt="Library" />
    <p>
      I’m a computer science student and research assistent at KTH, Stockholm. Working with AI and languages since 2017. Currently, I am working to develop new AI-based models that can be used in automating parts of the work done by TA’s in programming courses.
    </p>
    <p>
      Since the spring of 2023, I have been the Head of the Education team at the KTH AI Society. Working on projects concerning medical and educational applications of AI.
    </p>
    <p>
      Beyond my work, I have an interest for economics, physics and astrophysics, philosphy and classical music. 
    </p>
  </div>
);

export default About;
