import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet'; // Import Helmet for dynamic meta tags
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import '../assets/css/writings.css'; // Import the CSS file

const importAllImages = (r) => {
  let images = {};
  r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
  return images;
};

// Dynamically import all images from the writings folder
const images = importAllImages(require.context('../assets/writings/', true, /\.(png|jpg|jpeg|gif)$/));

const WritingDetail = () => {
  const { folder, slug } = useParams();
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [pageImage, setPageImage] = useState(''); // For the image shown on the page
  const [metaImage, setMetaImage] = useState(''); // For the image used in Open Graph tags

  useEffect(() => {
    // Dynamically import the markdown file
    import(`../assets/writings/${folder}/content.md`)
      .then(res => fetch(res.default))
      .then(res => res.text())
      .then(text => {
        const lines = text.split('\n');
        const firstHeader = lines.find(line => line.startsWith('# '));
        // Look for date in italic format (*Date: something*)
        const dateLine = lines.find(line => line.match(/\*Date:.*\*/));

        if (firstHeader) {
          setTitle(firstHeader.replace('# ', ''));
          text = text.replace(firstHeader, ''); // Remove the title line from the markdown content
        }

        if (dateLine) {
          // Extract date without the markdown syntax
          const cleanDate = dateLine.replace(/\*Date:\s*|\*/g, '').trim();
          setDate(cleanDate);
          // Remove the original date line from content
          text = text.replace(dateLine, ''); // Remove the date line from the markdown content
        }

        // Set the page image and meta image
        const metaImg = images[`${folder}/image1.jpg`]; // Image for Open Graph meta tags

        setMetaImage(metaImg);

        // Replace image paths in the markdown content
        const processedContent = text.replace(/<img src="([^"]+)" alt="([^"]*)"/g, (match, fileName, altText) => {
          const imagePath = images[`${folder}/${fileName}`];
          return `<img src="${imagePath}" alt="${altText}"`;
        });

        setContent(processedContent);
      })
      .catch(err => console.error('Error loading markdown content:', err));
  }, [folder]);

  return (
    <div id="writing-detail" className="content">
      <Helmet>
        <title>{title}</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={`An article by Avid Fayaz about ${title}`} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:url" content={`https://www.avidfayaz.com/writings/${folder}`} />
        <meta property="og:site_name" content="Avid Fayaz" />
      </Helmet>
      <div className="markdown-content">
        <h2>{title}</h2>
        {date && <p className="date"><em>{date}</em></p>} {/* Add class "date" */}
        {pageImage && <img src={pageImage} alt={title} style={{ maxWidth: '100%', height: 'auto' }} />}
        <div className="writing-content">
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]} 
            rehypePlugins={[rehypeRaw]}
            components={{
              em: ({node, ...props}) => <em {...props} /> // Ensure proper em rendering
            }}
          >
            {content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default WritingDetail;
