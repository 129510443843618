// src/components/Header.js
import React from 'react';
import headerImg from '../assets/images/raven3.png';
import '../assets/css/header.css';

const Header = () => (
  <header>
    <img src={headerImg} alt="Logo" id="header-img" width="100" height="100" />
    <h1 id="header-text">Fayaz Notes</h1>
  </header>
);

export default Header;
